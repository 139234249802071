import {
	Button,
	Card,
	Col,
	Descriptions,
	Form,
	Input,
	Modal,
	Row,
	Space,
	message,
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { TrpcBase } from '../../../utils/trpc';
import type { SuccessCallbackType } from './SuccessCallbackType';

export default function AddBuilding({ onSuccess }: SuccessCallbackType) {
	const [form] = Form.useForm();
	const create = TrpcBase.apartment.createBuilding.useMutation();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Modal
				title="添加楼宇"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<Form
					form={form}
					onFinish={async (values) => {
						console.log(values);

						try {
							const res = await create.mutateAsync(values);
							console.log(res);

							message.success('创建成功');
							setIsModalOpen(false);
							onSuccess?.();
						} catch (err) {
							if (err instanceof Error) {
								message.error(`创建失败 id不可重复: ${err?.message}`);
							}
						}
					}}
					onError={(v) => {
						console.log(v);
					}}
				>
					<Form.Item label="楼宇id" name="id" tooltip="独一无二，不可重复">
						<Input />
					</Form.Item>
					<Form.Item label="楼宇名" name="name">
						<Input />
					</Form.Item>
				</Form>
			</Modal>

			<Button
				className="my-2"
				onClick={() => {
					showModal();
				}}
			>
				添加楼宇
			</Button>
		</>
	);
}
