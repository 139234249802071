import { Button } from 'antd';
import { useNavigate } from 'react-router';
import { TrpcBase } from '../../../utils/trpc';
import { SuperDescription, localType } from '../components/SuperDescription';
import AddBuilding from './addBuilding';

const newLocal: localType = [
	{
		label: 'id',
		field: 'buildingId',
		isKey: true,
	},
	{
		label: '名称',
		field: 'buildingName',
		isTitle: true,
		isEdit: true,
	},
	{
		label: '创建人',
		field: 'ownerId',
	},
	{
		label: '创建时间',
		field: 'recordingTime',
		type: 'time',
	},
	{
		label: '更新时间',
		field: 'updateTime',
		type: 'time',
	},
	{
		label: '备注',
		field: 'descriptionNote',
		isEdit: true,
	},
];

export default function RoomConfigurePage() {
	const navigate = useNavigate();
	const buildingList = TrpcBase.apartment.buildingList.useQuery();
	const updateAction = TrpcBase.apartment.updateBuilding.useMutation();
	const deleteAction = TrpcBase.apartment.deleteBuilding.useMutation();

	return (
		<div className="m-2">
			<AddBuilding
				onSuccess={() => {
					buildingList.refetch();
				}}
			/>

			<div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-1">
				{buildingList.data?.map((i) => {
					return (
						<SuperDescription
							newLocal={newLocal}
							onFinish={async () => {
								await buildingList.refetch();
							}}
							updateAction={updateAction}
							deleteAction={deleteAction}
							key={i.buildingId}
							obj={i}
							actions={[
								<Button
									onClick={() => {
										navigate(`/apartment/roomConfigure/${i.buildingId}`);
									}}
								>
									房间规则配置
								</Button>,
								<Button
									onClick={() => {
										navigate(`/apartment/buildingTeam/${i.buildingId}`);
									}}
								>
									项目人员管理
								</Button>,
							]}
						/>
					);
				})}
			</div>
		</div>
	);
}
